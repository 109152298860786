// https://cloudinary.com/documentation/transformation_reference

export interface CloudinaryProps {
  crop?: "fill" | "fit" | "crop" | "auto";
  width?: number;
  height?: number;
  zoom?: number;
  opacity?: number;
  ratio?: `${string}:${string}`;
  gravity?:
    | "face"
    | "faces"
    | "north_west"
    | "north"
    | "north_east"
    | "east"
    | "center"
    | "west"
    | "south_west"
    | "south"
    | "south_east"
    | "auto"
    // User friendly names
    | "Left"
    | "Top"
    | "Right"
    | "Bottom"
    | "Center"
    | "Auto";

  quality?:
    | "q_auto"
    | "q_auto:best"
    | "q_auto:good"
    | "q_auto:eco"
    | "q_auto:low"
    | number;
  format?: "auto" | "svg" | "gif" | "webp" | "jpg" | "ico" | "pdf" | "png";
  rotate?: number | "auto_right" | "auto_left" | "vflip" | "hflip" | "ignore";
}

export function cloudinary(url?: string, props: CloudinaryProps = {}): string {
  if (!url) return "";
  // contentful sends protocol-relative image src
  if (url.startsWith("//")) url = "https:" + url;
  // does not work on local images
  if (!url.startsWith("http")) return url;
  // disable for storybook
  if (
    url.includes(".chromatic.") ||
    url.includes("hbs-design-system.netlify.app") ||
    url.includes("designsystem.hbs.edu")
  ) {
    return url;
  }

  const src = encodeURIComponent(url);

  const format = "f_" + (props.format || "webp");

  let crop = "c_fill";
  if (props.crop) crop = `c_${props.crop}`;

  let quality = "q_auto";
  if (props.quality) {
    const testQ = Number(props.quality);
    isNaN(testQ)
      ? (quality = props.quality.toString())
      : (quality = `q_${testQ}`);
  }

  const transforms: Array<string> = [quality];

  if (props.width || props.height || props.ratio) transforms.push(crop);

  // Convert user friendly names to cloudinary names
  if (props.gravity === "Left") props.gravity = "west";
  if (props.gravity === "Top") props.gravity = "north";
  if (props.gravity === "Right") props.gravity = "east";
  if (props.gravity === "Bottom") props.gravity = "south";
  if (props.gravity === "Center") props.gravity = "center";
  if (props.gravity === "Auto") props.gravity = "auto";

  if (props.width) transforms.push(`w_${props.width}`);
  if (props.height) transforms.push(`h_${props.height}`);
  if (props.rotate) transforms.push(`a_${props.rotate}`);
  if (props.ratio) transforms.push(`ar_${props.ratio}`);
  if (props.gravity) transforms.push(`g_${props.gravity}`);
  if (props.opacity) transforms.push(`o_${props.opacity}`);
  if (props.zoom) transforms.push(`z_${props.zoom}`);

  return `https://cloudinary.hbs.edu/hbsit/image/fetch/${transforms.join(
    ",",
  )}/${format}/${src}`;
}
